import './App.css';
import { useEffect, useRef, useState } from 'react';

import app from "./images/app.png"
import eigen from "./images/eigen.png"
import symbiotic from "./images/symbiotic.png"
import jito from "./images/jito.png"
import FRAGMETRIC from "./images/FRAGMETRIC.png"
import babylon from "./images/babylon.png"
import kernel from "./images/kernel.png"
import nodes from "./images/nodes.png"
import code from "./images/code.png"
import ivy from "./images/ivy.png"
import bgTop from "./images/bg-top.svg"
import bgGlow from "./images/bg-glow.png"
import { OptionsButton } from './components/optionsButton';
import contactBg from "./images/contact-bg.svg"
import { Link } from 'react-router-dom';
import MobileMenu from './components/MobileMenu';

export const useScrollAnimation = () => {
  const elementRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        threshold: 0.2,
        rootMargin: '0px',
      }
    );

    const currentElement = elementRef.current;
    if (currentElement) {
      observer.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        observer.unobserve(currentElement);
      }
    };
  }, []);

  return { elementRef, isVisible };
};

export const App = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const productsOptions = [
    { label: "Client", link: "https://docs.ivynet.dev/docs/client/introduction" },
    { label: "Devnet", link: "https://docs.ivynet.dev/docs/devnet/Introduction" },
    { label: "Release Notes", link: "https://docs.ivynet.dev/docs/release_notes/0.5_release_notes"}, 
  ]
  const operatorsOptions = [
    { label: "Docs", link: "https://docs.ivynet.dev/" },
    { label: "APIs", link: "https://docs.ivynet.dev/docs/client/api_spec" },
    { label: "GitHub", link: "https://github.com/ivy-net" },
  ]
  const CommunityOptions = [
    { label: "LinkedIn", link: "https://www.linkedin.com/company/ivynet-dev/" },
    { label: "X", link: "https://x.com/ivynet_dev" },
    { label: "Contact", link: "https://t.me/ivynetdotdev" },
  ]

  const { elementRef, isVisible } = useScrollAnimation();

  return (
    <div className='flex flex-col bg-appBg text-primary w-full gap-16 poppins-bold'>
      <img className='absolute top-0 z-[1] h-full left-0 right-0 mx-auto opacity-50 animate-glow-pulse animate-slow-rotate' src={bgGlow} alt="glow effect" />
      <div className='flex flex-col w-full gap-16' style={{ backgroundImage: `url(${bgTop})` }}>
        {/* Topbar */}
        <div className='flex px-32 py-6 justify-between'>
          <div className='flex items-center gap-1'>
            <img src={ivy} alt="ivy logo" />
            <div className='text-[#D6D6DA] text-xl font-semibold'>IvyNet</div>
          </div>

          {/* Desktop Navigation */}
          <div className='hidden lg:flex items-center gap-4 text-base font-normal pt-mono z-10'>
            <div className="relative group">
              <OptionsButton options={productsOptions}>
                <div className='hover:text-accent'>Products</div>
              </OptionsButton>
            </div>
            <div className="relative group">
              <OptionsButton options={operatorsOptions}>
                <div className='hover:text-accent'>Operators</div>
              </OptionsButton>
            </div>
            <div className="relative group">
              <OptionsButton options={CommunityOptions}>
                <div className='hover:text-accent'>Community</div>
              </OptionsButton>
            </div>
            <Link to="https://t.me/ivynetdotdev" className="cursor-pointer hover:text-accent">
              <div>Contact</div>
            </Link>
            <Link to="https://metrics.ivynet.dev/login" className="cursor-pointe text-accent font-bold hover:underline">
              <div>Log In</div>
            </Link>
          </div>
          <button
            className='lg:hidden z-10'
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
        </div>

        <MobileMenu
          isOpen={isMobileMenuOpen}
          onClose={() => setIsMobileMenuOpen(false)}
          productsOptions={productsOptions}
          operatorsOptions={operatorsOptions}
          communityOptions={CommunityOptions}
        />

        {/* Main */}
        <div className='flex flex-col justify-center gap-4 text-center'>
          <div className='font-bold text-[40px] text-accent'>Web3 Machine Intelligence</div>
          <div className='flex flex-col text-xl'>
            <div className='flex justify-center font-light w-full'>
              <p className='poppins-light'>Access</p>
              <p className='font-bold text-accent'>&nbsp;unified metrics and insights&nbsp;</p>
            </div>
            <p className='poppins-light'>across your node deployments</p>
          </div>
          <div className='flex gap-4 justify-center mt-6'>
            <button className='bg-accent text-black px-8 py-3 rounded-[4px] pt-mono z-10 transition-transform hover:scale-105'
             onClick={() => window.location.href = "https://metrics.ivynet.dev/signup"}>Get Started</button>
            <button className='text-accent px-8 py-3 rounded-[4px] border border-solid border-accent pt-mono z-10 transition-transform hover:scale-105'
            onClick={() => window.location.href = "https://docs.ivynet.dev/docs/client/QuickstartGuide"}>Docs</button>
          </div>
        </div>

        {/* App */}
        <div ref={elementRef} className='flex justify-center'>
          <img
            className={`z-10 transition-all duration-1000 transform w-[90%] ${
              isVisible
                ? 'opacity-100 scale-80'
                : 'opacity-40 scale-60'
            }`}
            src={app}
            alt="app"
          />
        </div>
        <div className='flex flex-col gap-2 lg:mt-[-520px] mb-16'>
          <div className='flex justify-center text-base poppins-light'>Supporting:</div>
          <div className='flex flex-wrap gap-6 justify-center max-w-[800px] mx-auto px-4 z-10'>
            <img className='w-[100px] h-auto' src={eigen} alt="eigen" />
            <img className='w-[100px] h-auto' src={symbiotic} alt="symbiotic" />
            <img className='w-[100px] h-auto' src={jito} alt="jito" />
            <img className='w-[100px] h-auto' src={FRAGMETRIC} alt="FRAGMETRIC" />
            <img className='w-[100px] h-auto' src={babylon} alt="babylon" />
            <img className='w-[100px] h-auto' src={kernel} alt="kernel" />
          </div>
        </div>
      </div>

      {/* Features */}
      <div className='flex flex-col gap-16 pt-32 px-8 lg:px-44'>
        {/* First feature */}
        <div className='flex max-lg:flex-col gap-24 items-center mx-4'>
          <div className='flex flex-col gap-4 lg:w-1/2 max-lg:text-center max-lg:items-center'>
            <div className='font-bold text-[32px] text-accent'>Optimize your Web3 deployments</div>
            <div className='font-light text-[22px] poppins-light'>Attach the IvyNet client for unified observability, enriched metrics, AI-powered diagnostics, and time-series insights</div>
            <button className='flex w-fit text-accent px-8 py-3 mt-3 rounded-[4px] border border-solid border-accent pt-mono transition-transform hover:scale-105'
            onClick={() => window.location.href = "https://docs.ivynet.dev/docs/client/QuickstartGuide"}>Run Client</button>
          </div>
          <img className='lg:w-1/2 max-w-[500px] max-lg:w-[90%]' src={nodes} alt="nodes" />
        </div>

        {/* Second feature */}
        <div className='flex max-lg:flex-col gap-24 items-center mx-4'>
          <div className='lg:order-2 flex flex-col gap-4 lg:w-1/2 max-lg:text-center max-lg:items-center'>
            <div className='font-bold text-[32px] text-accent'>Accessible through shared APIs</div>
            <div className='font-light text-[22px] poppins-light'>Collect system, health, and performance metrics with logs to orchestrate Web3 devops, compliance, and BD activities</div>
            <button className='flex w-fit text-accent px-8 py-3 mt-3 rounded-[4px] border border-solid border-accent pt-mono transition-transform hover:scale-105'
            onClick={() => window.location.href = "https://docs.ivynet.dev/docs/client/api_spec"}>See APIs</button>
          </div>
          <img className='lg:w-1/2 max-w-[500px] max-lg:w-[90%] lg:order-1' src={code} alt="code" />
        </div>
      </div>

      {/* Contact */}
      <div className='flex flex-col'>
        <div className="relative flex items-center py-48" style={{ backgroundImage: `url(${bgTop})` }} >
          <img className='absolute left top w-full h-full' src={contactBg} alt="contact-bg" />
          <div className='relative flex max-lg:flex-col gap-10 w-full justify-between items-center px-8 lg:px-44'>
          <div className='flex flex-col gap-4 text-center lg:text-left'>
            <div className='font-bold text-[32px] text-accent'>Get in touch</div>
            <div className='font-light text-[22px] poppins-light'>Join IvyNet to optimize your Web3 deployments</div>
            <div className="flex gap-4 justify-center lg:justify-start">
              <button className='bg-accent text-black px-8 py-3 rounded-[4px] pt-mono transition-transform hover:scale-105'
              onClick={() => window.location.href = "https://metrics.ivynet.dev/signup"}>Sigh Up</button>
              <button className='text-accent px-8 py-3 rounded-[4px] border border-solid border-accent pt-mono transition-transform hover:scale-105'
              onClick={() => window.location.href = "https://t.me/ivynetdotdev"}>DM</button>
            </div>
          </div>
          </div>
        </div>
        <div className='flex justify-center py-4 text-base font-light poppins-light'>Copyright © 2025 IvyNet</div>
      </div>

    </div>
  );
}
